<template>
  <v-app>
    <v-app-bar v-show="!$router.currentRoute.meta.hideAppBar && !$route.query.hasOwnProperty('embedded')" dark app color="teal darken-3">
      <v-toolbar-title class="headline text-uppercase">
        <span @click="$router.push('/')"><span class="hidden-xs-only">Yodaforex&nbsp;</span><span>Genepi</span></span>
      </v-toolbar-title>
      <v-spacer/>
      <y-auth-button v-if="!$router.currentRoute.meta.isPublic" v-model="isAuth" success-color="light-green accent-2" error-color="orange"/>
    </v-app-bar>

    <v-main>
      <router-view v-if="isAuth || $router.currentRoute.meta.isPublic"/>
      <v-container v-else>
        <div class="d-flex justify-center">
          <v-progress-linear indeterminate/>
        </div>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      isAuth: false
    }
  }
};
</script>

